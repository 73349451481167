/*--------------------------------------
		CUSTOM FUNCTION WRITE HERE		
--------------------------------------*/
"use strict";
jQuery(document).on('ready', function() {
	/*--------------------------------------
			MOBILE MENU						
	--------------------------------------*/
	function collapseMenu(){
		jQuery('.tg-navigation ul li.menu-item-has-children, .tg-navdashboard ul li.menu-item-has-children, .tg-navigation ul li.menu-item-has-mega-menu').prepend('<span class="tg-dropdowarrow"><i class="fa fa-angle-down"></i></span>');
		jQuery('.tg-navigation ul li.menu-item-has-children span, .tg-navdashboard ul li.menu-item-has-children span, .tg-navigation ul li.menu-item-has-mega-menu span').on('click', function() {
			jQuery(this).parent('li').toggleClass('tg-open');
			jQuery(this).next().next().slideToggle(300);
		});
	}
	collapseMenu();

	/*--------------------------------------
			SHOW NUMBER						
	--------------------------------------*/
	var _clickelement = jQuery('.tg-btnphone');
	_clickelement.on('click', 'span', function() {
		jQuery(this).find('em').text(jQuery(this).data('last') );
	});
	/*--------------------------------------
			TESTIMONIALS SLIDER				
	--------------------------------------*/
	if(jQuery('#tg-testimonialsslider').length > 0){
		var _tg_testimonialsslider = jQuery('#tg-testimonialsslider');
		_tg_testimonialsslider.owlCarousel({
			items : 2,
			nav: true,
			margin: 30,
			dots: false,
			autoplay: true,
			dotsClass: 'tg-sliderdots',
			navClass: ['tg-prev', 'tg-next'],
			navContainerClass: 'tg-slidernav',
			navText: ['<span class="icon-chevron-left"></span>', '<span class="icon-chevron-right"></span>'],
			responsive:{
				0:{
					items: 1,
				},
				768:{
					items: 2,
				},
			}
		});
	}


	/*--------------------------------------
			AUTHOR ADS SLIDER				
	--------------------------------------*/
	if(jQuery('#tg-authoradsslider').length > 0){
		var _tg_authoradsslider = jQuery('#tg-authoradsslider');
		_tg_authoradsslider.owlCarousel({
			items : 1,
			nav: true,
			loop: true,
			dots: false,
			autoplay: true,
			dotsClass: 'tg-sliderdots',
			navClass: ['tg-prev', 'tg-next'],
			navContainerClass: 'tg-slidernav',
			navText: ['<span class="icon-chevron-left"></span>', '<span class="icon-chevron-right"></span>'],
		});
	}

	/*--------------------------------------
			THEME ACCORDION 				
	--------------------------------------*/
	if(jQuery('.tg-panelheading').length > 0){
		var _tg_panelheading = jQuery('.tg-panelheading');
		_tg_panelheading.on('click',function () {
			jQuery('.panel-heading').removeClass('active');
			jQuery(this).parents('.panel-heading').addClass('active');
			jQuery('.panel').removeClass('active');
			jQuery(this).parent().addClass('active');
		});
	}
	
	/*--------------------------------------
			THEME COLLAPSE					
	--------------------------------------*/
	if(jQuery('#tg-narrowsearchcollapse').length > 0){
		var _openFirst = jQuery('#tg-narrowsearchcollapse');
		_openFirst.collapse({
			open: function() {this.slideDown(300);},
			close: function() {this.slideUp(300);},
		});
		_openFirst.trigger('open');
	}
/*--------------------------------------
			TRANDING POST SLIDER 			
	--------------------------------------*/
	if(jQuery('#tg-trendingpostsslider').length > 0){
		var _tg_trendingpostsslider = jQuery('#tg-trendingpostsslider');
		_tg_trendingpostsslider.owlCarousel({
			items : 1,
			nav: true,
			loop: true,
			dots: false,
			autoplay: true,
			dotsClass: 'tg-sliderdots',
			navClass: ['tg-prev', 'tg-next'],
			navContainerClass: 'tg-slidernav',
			navText: ['<span class="icon-chevron-left"></span>', '<span class="icon-chevron-right"></span>'],
		});
	}
	if(jQuery('#tg-themecollapse').length > 0){
		var _tg_themecollapse = jQuery('#tg-themecollapse');
		_tg_themecollapse.collapse({
			accordion: true,
			query: '.tg-collaptabpane h3',
			close: function() {this.slideUp(300);},
			open: function() {this.slideDown(300);},
		});
	}
	/*--------------------------------------
			THEME VERTICAL SCROLLBAR		
	--------------------------------------*/
	if(jQuery('.tg-verticalscrollbar').length > 0){
		var _tg_verticalscrollbar = jQuery('.tg-verticalscrollbar');
		_tg_verticalscrollbar.mCustomScrollbar({
			axis:"y",
		});
	}
	if(jQuery('.tg-horizontalthemescrollbar').length > 0){
		var _tg_horizontalthemescrollbar = jQuery('.tg-horizontalthemescrollbar');
		_tg_horizontalthemescrollbar.mCustomScrollbar({
			axis:"x",
			advanced:{autoExpandHorizontalScroll:true},
		});
	}
	


});